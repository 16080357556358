<template>
	
	<div class="container-fluid my-campaigns">
		<!--
			id         
			name		
			id_user    
			id_email   
			id_from    
			id_subject 
			id_image   
			header     
			email_test 
			returnPath 
			mainIP     
			id_domain  
			id_list    
			id_negative
			pid        
			status     
			created_at 
			updated_at 
		-->
		
		<!--h4 class="c-grey-900 mT-10 mB-30">My Campaigns</h4-->
		<div class="row mT-10 mB-30">
			<div class="col-6">
				<h4 class="c-grey-900 m-0">{{ $t('mycampaigns_1')[0] }}</h4>
			</div>
			<div class="col-6 text-right">
				<router-link :to="{name: 'campaign.create'}" class="btn btn-primary btn-sm">
					<i class="fa fa-plus"></i> {{ $t('mycampaigns_1')[1] }}
				</router-link>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="bgc-white bd bdrs-3 p-20 mB-20">
					<div class="row form-group">
						<div class="col-4">
							<!--h4 class="c-grey-900 mB-20">Campaigns</h4-->
							<!--div class="form-group">
								<select id="id_list" class="form-control">
									<option disabled>Choose...</option>
									<option>Email Campaigns</option>
									<option>Facebook Campaigns</option>
								</select>
							</div-->
							<multi-select :options="VisibleColumns" :selected="SelectedFields" label="Columns" placeholderType="title" :placeholder="$t('mycampaigns_2')[0]" 
							:caret="true" width="175px" @change="ShowHideCols"></multi-select>
						</div>
						<div class="col-8">
							<div class="form-inline pull-right">
								<div class="form-group">
									<date-picker right v-model="daterange"></date-picker>
								</div>
								<div class="form-group ml-2">
									<input type="search" class="form-control" :placeholder="$t('mycampaigns_2')[1]" v-model="search" />
								</div>
							</div>
						</div>
					</div>
					
					<div class="row">
						<div class="col-md-12">
							<div class="nav nav-tabs nav-fill status-tabs">
								<span v-for="(tab, id) in TabsStatus" v-bind:key="`tab-${id}`" :class="['nav-item nav-link c-pointer', tab.enable ? 'active' : '']" @click="ToggleStatus(tab)">
									{{ tab.title }} ({{ CountStatus[tab.id] }})
								</span>
							</div>
							<!--div class="tab-content tab-container-md">
								<div :class="['tab-pane fade', tabsSMTP[0].enable ? 'show active' : '']">
									
								</div>
							</div-->
						</div>
						<div class="col-md-12">
							<simple-table :dataItems="Campaigns" :columns="columns" :footer="true"><!-- :responsive="true" -->
								<template v-slot:body="slotProps">
									<tr v-for="(entry, id) in slotProps.body" v-bind:key="id">
										<template v-for="(item, key) in columns">
											<td v-if="item.enable" v-bind:key="key">
												<div v-if="item.column == 'actions'" class="text-center">
													<drop-down :items="columns" :action="CloseOptions" @toggle="CloseOptions = ''">
														<template v-slot:label>
															<i class="fa fa-ellipsis-v c-pointer p-3 m-auto" style="font-size:18px;color: #2d2d2d;"></i>
														</template>
														<template v-slot:options>
															<li v-for="(action, index) in OptionsAction" v-bind:key="`li-${index}`" @click="CampaignAction(action, id, entry)" class="icon-middle">
																<a href="javascript:void(0);" class="d-b td-n p-5 bgcH-grey-100 c-grey-700" v-if="action.status.includes(entry['status'])">
																	<i :class="[action.icon, 'm-5']"></i>
																	<span>{{ action.label }}</span>
																</a>
															</li>
														</template>
													</drop-down>
												</div>
												<div v-else-if="item.column == 'name'">
													<div style="color: rgb(121, 121, 121);">
														<div :title="entry[item.column]">
															{{ entry[item.column] | MaxLength }} 
															<span v-if="isIsset(entry['sender']) && entry['sender'].status == 'waiting'" class="badge badge-secondary text-capitalize">
																{{ entry['sender'].status }}
															</span>
															<span v-else :class="['badge', 'badge-' + BadgeStatus[entry['status']], 'text-capitalize' ]">
																{{ entry['status'] }} <i class="fa fa-cog fa-spin" v-if="entry['status'] == 'run'"></i>
															</span>
														</div>
														<b class="fs-11">Published on : </b><span>{{ entry['createdAt'] | date_format }}</span>
													</div>
												</div>
												<div v-else-if="['started_at', 'finished_at'].includes(item.column)" :class="[item.class]">
													{{ entry[item.column] | date_format }}
												</div>
												<!--div v-else-if="item.column == 'sender'" class="text-center">
													<span v-if="entry['sender'] == null" class="badge badge-light">For Test</span>
													<span v-else :class="['badge', 'badge-' + BadgeSender[entry['sender'].status], 'text-capitalize' ]">
														{{ entry['sender'].status }}
													</span>
												</div-->
												<div v-else :class="[item.class]">
													{{ entry[item.column] }}
												</div>
											</td>
										</template>
									</tr>
								</template>
							</simple-table>
							<pagination v-bind:lastPage="lastPage" v-on:page-changed="onPageChange" v-bind:current-page="currentPage" :maxVisiblePages="maxVisiblePages" />
						</div>
					</div>
					
					<modal size="confirm" v-show="ModalObj.DeleteCampaign" hide-header hide-footer>
						<template v-slot:body>
							<div class="text-center">
								<div>
									<svg xmlns="http://www.w3.org/2000/svg" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);width:6em; height:6em;" viewBox="0 0 512 512">
										<path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192s192-86 192-192z" fill="none" stroke="#ea1c0d" stroke-miterlimit="10" stroke-width="32"/>
										<path fill="none" stroke="#ea1c0d" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M320 320L192 192"/>
										<path fill="none" stroke="#ea1c0d" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M192 320l128-128"/>
									</svg>
								</div>
								<div>
									<h4>{{ $t('mycampaigns_3')[0] }}</h4>
									<p class="m-0" style="font-size: 13px;">{{ $t('mycampaigns_3')[1] }}</p>
									<h6>{{ DataCampaign.name }}</h6>
								</div>
								<div>
									<button class="btn btn-secondary mR-10" @click="ModalObj.DeleteCampaign = false">{{ $t('mycampaigns_3')[2] }}</button>
									<button class="btn btn-danger" @click="DeleteCampaign">
										<i class="fa fa-cog fa-spin" v-if="Loading.DeleteCampaign"></i> 
										<i class="ti-trash mR-2 default-size" v-else></i> <span>{{ $t('mycampaigns_3')[3] }}</span>
									</button>
								</div>
							</div>
						</template>
					</modal>
					
					<modal size="confirm" v-show="ModalObj.Replicate" hide-header hide-footer>
						<template v-slot:body>
							<div class="text-center">
								<div class="pb-2">
									<i class="ti-info-alt text-primary" style="font-size: 100px;"></i>
								</div>
								<div>
									<h4>{{ $t('mycampaigns_4')[0] }}</h4>
									<p class="m-0" style="font-size: 13px;">{{ $t('mycampaigns_4')[1] }}</p>
									<h6>{{ DataCampaign.name }}</h6>
								</div>
								<div>
									<button class="btn btn-secondary mR-10" @click="ModalObj.Replicate = false">{{ $t('mycampaigns_4')[2] }}</button>
									<button class="btn btn-primary" @click="ReplicateCampaign">
										<i class="fa fa-cog fa-spin" v-if="Loading.Replicate"></i> 
										<i class="ti-files mR-2 default-size" v-else></i> <span>{{ $t('mycampaigns_4')[3] }}</span>
									</button>
								</div>
							</div>
						</template>
					</modal>
					
					<modal size="confirm" v-show="ModalObj.Initialize" hide-header hide-footer>
						<template v-slot:body>
							<div class="text-center">
								<div class="pb-2">
									<i class="ti-info-alt text-primary" style="font-size: 100px;"></i>
								</div>
								<div>
									<h4>{{ $t('mycampaigns_5')[0] }}</h4>
									<p class="m-0" style="font-size: 13px;">{{ $t('mycampaigns_5')[1] }}</p>
									<h6>{{ DataCampaign.name }}</h6>
								</div>
								<div>
									<button class="btn btn-secondary mR-10" @click="ModalObj.Initialize = false">{{ $t('mycampaigns_5')[2] }}</button>
									<button class="btn btn-primary" @click="InitializeCampaign">
										<i class="fa fa-cog fa-spin" v-if="Loading.Initialize"></i> 
										<i class="ti-files mR-2 default-size" v-else></i> <span>{{ $t('mycampaigns_5')[3] }}</span>
									</button>
								</div>
							</div>
						</template>
					</modal>
					
					<modal size="max" v-show="ModalObj.EditCampaign" @close="ModalObj.EditCampaign = false" :title="$t('mycampaigns_6', {name: DataCampaign.name})"><!-- :scrollHide="CurrentTab == 'general'" -->
						<template v-slot:body>
							<div class="row">
								<div class="col-12">
									<tabs :Items="TabsItems" :fill="false" @change="ChangeTabs" :current="CurrentTab"></tabs>
									
									<div class="tabs-content p-15 label-weight content-scroll border-top-0">
										<div v-show="CurrentTab == 'general'">
											<general-details :campaign="DataCampaign" @onChange="ChangeDetails"></general-details>
										</div>
										<div v-show="CurrentTab == 'content'">
											<email-content :campaign="DataCampaign" @onChange="ChangeDetails"></email-content>
										</div>
										<div v-show="CurrentTab == 'sender'">
											<sender-smtp :campaign="DataCampaign" @onChange="ChangeDetails"></sender-smtp>
										</div>
										<div v-show="CurrentTab == 'suppfile'">
											
											<div class="row form-group">
												<div class="col-2">
													<h3>{{ $t('mycampaigns_7')[0] }}</h3>
												</div>
												<div class="col-10 pl-0 my-auto"><hr class="border-default-color"></div>
											</div>
											<div class="row form-group">
												<div class="col-8 mx-auto">
													<suppression-file :ExistingFile="ExistingFile" @onChoose="SuppFileChange" @toggletab="ExistingFile = !ExistingFile" 
													:CheckSuppFile="CheckSuppFile" :DefaultFile="SuppFile.id" :Refresh="RefreshFiles"></suppression-file>
												</div>
												<div class="col-8 mx-auto text-right">
													<button type="button" class="btn btn-primary" @click="SuppFileValidate">
														<i :class="['fa', Loading.SuppFile ? 'fa-cog fa-spin' : 'fa-check']"></i> {{ $t('mycampaigns_7')[1] }}
													</button>
												</div>
											</div>
											
										</div>
										<div v-show="CurrentTab == 'dnsrecords'">
											<div class="col-8 mx-auto">
												<dns-records :DomainName="DomainName" :DNSRecords="DNSRecords"></dns-records>
											</div>
										</div>
									</div>
									
								</div>
							</div>
						</template>
					</modal>
					
					<modal v-show="ModalObj.Schedule" @close="ModalObj.Schedule = false" size="xs" :title="$t('mycampaigns_8')[0]">
						<template v-slot:body>
							<div class="row label-weight">
								<div class="form-group col-md-12">
									<label for="MyDate">{{ $t('mycampaigns_8')[1] }}</label><!--  :min="ScheduleMinDate" -->
									<!--input type="date" id="MyDate" class="form-control" :min="ScheduleMinDate" v-model="ScheduleDateTime" /-->
									<date-time v-model="DataCampaign.schedule" input-id="MyDate" input-class="form-control"
									:min-datetime="ScheduleMinDate" :placeholder="$t('mycampaigns_8')[2]" type="datetime"></date-time>
									<small class="form-text text-danger" v-if="DataCampaign.schedule == ''">{{ $t('mycampaigns_8')[3] }}</small>
									<!--small class="form-text text-muted" v-else>Your can change it from table campaign</small-->
								</div>
							</div>
						</template>
						<template v-slot:footer>
							<div>
								<button type="button" class="btn btn-primary" @click="SaveCampaign('Schedule')" :disabled="Loading.Schedule">
									<i class="fa fa-cog fa-spin" v-if="Loading.Schedule"></i> 
									<i class="fa fa-calendar" v-else></i> {{ $t('mycampaigns_8')[4] }}
								</button>
							</div>
						</template>
					</modal>
					
					<modal v-show="ModalObj.Recipients" @close="ModalObj.Recipients = false" size="sm" :title="$t('mycampaigns_9')[0]" scroll-hide>
						<template v-slot:body>
							<!-- label-weight -->
							<!--div class="row form-group">
								<div class="col-4">
									<h5>Recipients: <span v-if="Recipients.total_emails > 0">({{Recipients.total_emails}})</span></h5>
								</div>
								<div class="col-8 pl-0 my-auto"><hr class="border-default-color"></div>
							</div-->
							<div class="form-group row label-weight">
								<div class="col-md-4 mt-2">
									<label class="m-0">{{ $t('mycampaigns_9')[1] }} <!--b style="color: red;">*</b--></label>
								</div>
								<div class="col-md-8">
									<multi-select :options="MyLists" :selected="Recipients.ListIds" :label="$t('mycampaigns_9')[2]" placeholderType="title" :placeholder="$t('mycampaigns_9')[3]" 
									select-all :caret="true" width="100%" @change="ChooseRecipients('list')"></multi-select>
									<div class="row">
										<div class="col-md-7">
											<small class="form-text text-danger" v-if="ValidateObj.lists_id">{{ $t('mycampaigns_9')[4] }}</small>
										</div>
										<div class="col-md-5 text-right">
											<check-box id="shuffle" v-model="Recipients.shuffle_rcp" :label="$t('mycampaigns_9')[5]"></check-box>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group row label-weight">
								<div class="col-md-6">
									<label>{{ $t('mycampaigns_10')[0] }}</label>
									<multi-select :options="RecipientsStatus" :selected="Recipients.RcptsList" :label="$t('mycampaigns_10')[1]" placeholderType="title" 
									:placeholder="$t('mycampaigns_10')[2]" select-all :caret="true" width="100%" @change="ChooseRecipients('status')"></multi-select>
									<!-- :defaultSelected="RcpSelected" -->
									<small class="form-text text-danger" v-if="ValidateObj.recipients">{{ $t('mycampaigns_10')[3] }}</small>
									<template v-else>
										<small class="form-text text-primary" v-if="count_recipients.hardbounce > 0" v-html="$t('mycampaigns_11', { count:count_recipients.hardbounce })"></small> <!-- We ignore it -->
									</template>
								</div>
								<div class="col-md-6">
									<label>{{ $t('mycampaigns_12')[0] }}</label>
									<input type="number" :class="['form-control', ValidateObj.total_emails ? 'is-invalid':'']" min="1" v-model.number.trim="Recipients.total_emails" :placeholder="$t('mycampaigns_12')[1]" 
									onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" />
									<small class="form-text text-danger" v-if="ValidateObj.total_emails">{{ $t('mycampaigns_12')[2] }}</small>
								</div>
							</div>
							
						</template>
						<template v-slot:footer>
							<div>
								<button type="button" class="btn btn-primary" @click="ChangeRecipients" :disabled="Loading.Recipients">
									<i class="fa fa-cog fa-spin" v-if="Loading.Recipients"></i> 
									<i class="fa fa-save" v-else></i> {{ $t('mycampaigns_12')[3] }}
								</button>
							</div>
						</template>
					</modal>
					
					<modal v-show="ModalObj.RcpsList" @close="ModalObj.RcpsList = false" size="lg" :title="$t('mycampaigns_13')" scroll-hide hide-footer :Loading="Loading.RcptList">
						<template v-slot:body>
							<div class="row form-group">
								<div class="col-6 my-auto">
									<span v-html="$t('mycampaigns_14', { total: RecipientsList.length })"></span>
								</div>
								<div class="col-6 text-right">
									<div class="form-inline pull-right">
										<div class="form-group ml-2">
											<multi-select :options="VisibleFields" :selected="SelectedColumns" :label="$t('mycampaigns_15')[0]" placeholderType="title" :placeholder="$t('mycampaigns_15')[1]" 
											:caret="true" width="175px" @change="ShowHideColumns"></multi-select><!-- select-all -->
										</div>
										<div class="form-group ml-2">
											<multi-select :options="RcptsStatus" :selected="SelectedActions" :label="$t('mycampaigns_15')[2]" placeholderType="title" :placeholder="$t('mycampaigns_15')[3]" 
											:caret="true" width="175px" @change="ShowHideActions" :selectAll="true"></multi-select><!-- select-all -->
										</div>
									</div>
								</div>
							</div>
							
							<div class="row">
								<div class="col-12">
									
									<data-table id="recipientslist" :dataItems="RecipientsList" :columns="RcptsColumns" :responsive="true" size="sm"><!-- @actionClick="ActionClick" -->
										<template v-slot:body="slotProps">
											<tr v-for="(entry, id) in slotProps.body" v-bind:key="id">
												<template v-for="(item, key) in RcptsColumns">
													<td v-if="item.enable && item.column == 'checkBox'" v-bind:key="`k-${key}`" @click.prevent="checkBoxRow(entry.id)" :style="{width: item.width}">
														<check-box :id="`selectItems-${entry.id}`" v-model="SelectedRcpts" :val="entry.id"></check-box>
													</td>
													<td v-else-if="item.enable" v-bind:key="`key-${key}`" @click="checkBoxRow(entry.id)" :class="[item.class]" :style="{width: item.width}">
														<div v-if="item.column == 'device'" class="text-capitalize">
															<i :class="['fa', 'fa-' + entry[item.column]]" v-if="entry[item.column] != ''"></i> {{ entry[item.column] }}
														</div>
														<div v-else-if="item.column == 'action'" class="text-capitalize fw-500">
															{{ entry[item.column] == 'bounced' ? 'failed' : entry[item.column] }}
														</div>
														<div v-else :title="entry[item.column]">
															{{ entry[item.column] | maxLength }}
														</div>
													</td>
												</template>
											</tr>
										</template>
									</data-table>
								</div>
							</div>
							
						</template>
					</modal>
					
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	import { SimpleTable, DropDown, Pagination, DatePicker, Modal, Tabs, DateTime, MultiSelect, CheckBox, DataTable } from "@/components";
	import { SuppressionFile, GeneralDetails, EmailContent, SenderSmtp, DnsRecords } 	from "./Components/Email";
	
	export default {
		name: 'MyCampaigns',
		components: {
			//
			SimpleTable,
			DropDown,
			Pagination,
			DatePicker,
			Modal,
			Tabs,
			DateTime,
			GeneralDetails,
			EmailContent,
			SenderSmtp,
			SuppressionFile,
			DnsRecords,
			
			MultiSelect,
			CheckBox,
			
			DataTable
		},
		data() {
			return {
				// Recipients -------------------------------
				RecipientsStatus: [
					{ id: 'fresh'	  ,	option: this.$t('mycampaigns_16')[0], title: this.$t('mycampaigns_16')[0], tag: '' },
					{ id: 'received'  , option: this.$t('mycampaigns_16')[1], title: this.$t('mycampaigns_16')[1], tag: '' },
					{ id: 'open'	  , option: this.$t('mycampaigns_16')[2], title: this.$t('mycampaigns_16')[2], tag: '' },
					{ id: 'click'	  ,	option: this.$t('mycampaigns_16')[3], title: this.$t('mycampaigns_16')[3], tag: '' },
					{ id: 'unsub'	  , option: this.$t('mycampaigns_16')[4], title: this.$t('mycampaigns_16')[4], tag: '' },
					{ id: 'softbounce',	option: this.$t('mycampaigns_16')[5], title: this.$t('mycampaigns_16')[5], tag: '' }, // Soft Bounce
				],
				RcptsStatus		: [
					{ id: 'fresh'	  ,	option: this.$t('mycampaigns_17')[0], title: this.$t('mycampaigns_17')[0], tag: '' },
					{ id: 'sent'	  ,	option: this.$t('mycampaigns_17')[1], title: this.$t('mycampaigns_17')[1], tag: '' },
					{ id: 'received'  , option: this.$t('mycampaigns_17')[2], title: this.$t('mycampaigns_17')[2], tag: '' },
					{ id: 'open'	  , option: this.$t('mycampaigns_17')[3], title: this.$t('mycampaigns_17')[3], tag: '' },
					{ id: 'click'	  ,	option: this.$t('mycampaigns_17')[4], title: this.$t('mycampaigns_17')[4], tag: '' },
					{ id: 'unsub'	  , option: this.$t('mycampaigns_17')[5], title: this.$t('mycampaigns_17')[5], tag: '' },
					{ id: 'softbounce',	option: this.$t('mycampaigns_17')[6], title: this.$t('mycampaigns_17')[6], tag: '' }, // Soft Bounce
				],
				SelectedActions	: [],
				MyLists			: [],
				
				count_recipients: { fresh : 0, received : 0, open : 0, click : 0, unsub : 0, softbounce : 0, hardbounce: 0 },
				ValidateObj		: { lists_id: false, recipients: false, total_emails: false },
				
				RecipientsList	: [],
				SelectedRcpts	: [],
				
				RcptsColumns 	: [
					// { title: ""		 		   	, column: 'checkBox'  , enable: true , class: '', width: '48px', checked: false },
					// { title: "Lead Details"		, column: 'id' 	  	  , enable: true , class: '', width: '250px' },
					
					{ title: this.$t('mycampaigns_18')[0]	, column: 'full_name' 		, enable: false, class: '', width: '120px' },
					{ title: this.$t('mycampaigns_18')[1]	, column: 'first_name'		, enable: false, class: '', width: '120px' },
					{ title: this.$t('mycampaigns_18')[2]	, column: 'last_name' 		, enable: false, class: '', width: '120px' },
					{ title: this.$t('mycampaigns_18')[3]	, column: 'email' 	  		, enable: true , class: '', width: '190px' },
					{ title: this.$t('mycampaigns_18')[4]	, column: 'phone' 	  		, enable: false, class: '', width: '140px' },
					{ title: this.$t('mycampaigns_18')[5]	, column: 'action' 	  		, enable: true , class: '', width: '90px' }, // text-center
					{ title: this.$t('mycampaigns_18')[6]	, column: 'device'	  		, enable: true , class: '', width: '90px' },
					{ title: this.$t('mycampaigns_18')[7] 	, column: 'datetime_zone'	, enable: true, class: '', width: '190px' },
					{ title: this.$t('mycampaigns_18')[8]	, column: 'countryname'	  	, enable: true , class: '', width: '140px' },
					{ title: this.$t('mycampaigns_18')[9]	, column: 'region'		  	, enable: true , class: '', width: '140px' },
					{ title: this.$t('mycampaigns_18')[10]	, column: 'city'	  	  	, enable: true , class: '', width: '130px' },
					{ title: this.$t('mycampaigns_18')[11]	, column: 'timezone'	  	, enable: true , class: '', width: '140px' },
					{ title: this.$t('mycampaigns_18')[12]	, column: 'continentname' 	, enable: false, class: '', width: '120px' },
					{ title: this.$t('mycampaigns_18')[13]	, column: 'currencycode'  	, enable: false, class: '', width: '100px' },
					
					// { title: 'Options'		, column: 'options'   , enable: true , class: 'text-center' }
				],
				SelectedColumns	: [],
				VisibleFields	: [],
				current_page : 0,
				last_page	 : 0,
				// Recipients -------------------------------
				
				
				// Tabs details ----------------------------
				
				TabsItems 	 : [
					{ id: 'general', 	label: this.$t('mycampaigns_19')[0], enable:	false, count: ''  },
					{ id: 'content', 	label: this.$t('mycampaigns_19')[1], enable:	false, count: ''  },
					{ id: 'sender', 	label: this.$t('mycampaigns_19')[2], enable:	false, count: '' },
					{ id: 'dnsrecords', label: this.$t('mycampaigns_19')[3], enable:	null, count: '' },
					
					// { id: 'suppfile', 	label: "Suppression File", 	enable:	false, count: '' },
					// { id: 'recipients', label: "Recipients", 	  	enable:	false,	count: '0' },
					// { id: 4, label: "Notes",		  	enable:	false, 	count: '' },
				],
				CurrentTab	 : 'general',
				
				// Tabs details ----------------------------
				
				// Pagination ----------------------------
				currentPage		: 1,
				lastPage		: 1,
				maxVisiblePages	: 3,
				totalCampaigns	: 0,
				// itemsPerPage	: 10,
				// Pagination ----------------------------
				
				// Filter ----------------------------
				status			: 'all',
				search			: '',
				daterange		: '',
				// Filter ----------------------------
				
				ScheduleMinDate	: '',
				ModalObj		: {DeleteCampaign: false, EditCampaign: false, Schedule: false, Recipients: false, RcpsList: false, Replicate: false, Initialize: false},
				Loading			: {DeleteCampaign: false, Schedule: false, SuppFile: false, Recipients: false, Replicate: false, Initialize: false, RcptList: false},
				DataCampaign 	: { name: '', schedule: '' },
				
				Recipients		: { ListIds: [], RcptsList: [], total_emails: false, shuffle_rcp: false },
				
				CurrentRowId	: '',
				
				CountStatus	 : { all: 0, 'new': 0, draft: 0, schedule: 0, run: 0, suspend: 0, finish: 0 },
				BadgeStatus	 : { 'new': 'primary', draft: 'dark', schedule: 'warning', run: 'success', suspend: 'secondary', finish: 'info' },
				BadgeSender	 : { 'waiting': 'secondary', 'ready': 'success', 'sending': 'primary', 'busy': 'warning' }, // , 'inprocess': 'info'
				TabsStatus	 : [
					{ id: 'all', 		 title: this.$t('mycampaigns_20')[0], enable:true },
					{ id: 'new',		 title: this.$t('mycampaigns_20')[1], enable:false },
					{ id: 'draft',		 title: this.$t('mycampaigns_20')[2], enable:false },
					{ id: 'schedule',	 title: this.$t('mycampaigns_20')[3], enable:false },
					{ id: 'run',	 	 title: this.$t('mycampaigns_20')[4], enable:false }, // inprogress
					{ id: 'suspend',	 title: this.$t('mycampaigns_20')[5], enable:false },
					{ id: 'finish',		 title: this.$t('mycampaigns_20')[6], enable:false },
				],
				columns		 : [
					{ title: this.$t('mycampaigns_21')[0], column: 'id' 		    , enable: false, class: ''			  },
					{ title: this.$t('mycampaigns_21')[1], column: 'name' 	    , enable: true , class: '' 			  },
					{ title: this.$t('mycampaigns_21')[2], column: 'sender_title', enable: false, class: ''	  },
					{ title: this.$t('mycampaigns_21')[3], column: 'total_emails', enable: true , class: 'text-center' },
					{ title: this.$t('mycampaigns_21')[4], column: 'total_sent'	, enable: true , class: 'text-center' },
					// { title: this.$t('mycampaigns_21')[5], column: 'total_rest'	, enable: true , class: 'text-center' },
					// { title: this.$t('mycampaigns_21')[6], column: 'time_finish'	, enable: true , class: 'text-center' },
					{ title: this.$t('mycampaigns_21')[7], column: 'received'	, enable: true , class: 'text-center' },
					{ title: this.$t('mycampaigns_21')[8], column: 'bounced'		, enable: true , class: 'text-center' }, // Bounced
					{ title: this.$t('mycampaigns_21')[9], column: 'openers' 	, enable: false, class: 'text-center' },
					{ title: this.$t('mycampaigns_21')[10], column: 'clickers' 	, enable: false, class: 'text-center' },
					{ title: this.$t('mycampaigns_21')[11], column: 'unsub' 		, enable: true , class: 'text-center' },
					{ title: this.$t('mycampaigns_21')[12], column: 'started_at'	, enable: false, class: '' },
					{ title: this.$t('mycampaigns_21')[13], column: 'finished_at'	, enable: false, class: '' },
					{ title: this.$t('mycampaigns_21')[14], column: 'actions'   	, enable: true , class: 'text-center' }
				],
				SelectedFields	: [],
				VisibleColumns	: [],
				Campaigns	 	: [],
				
				OptionsAction	: [
					{id: 'run', 		label: this.$t('mycampaigns_22')[0], icon: 'ti-target',			status: [ 'new', 'schedule' ]},
					{id: 'edit',	 	label: this.$t('mycampaigns_22')[1], icon: 'ti-pencil-alt', 	status: [ 'draft' ]},
					{id: 'edit', 		label: this.$t('mycampaigns_22')[2], icon: 'ti-pencil-alt',		status: [ 'new', 'schedule', 'finish', 'run' ]}, // , 'suspend'
					{id: 'recipients',	label: this.$t('mycampaigns_22')[3], icon: 'ti-layout-list-thumb',	status: [ 'new', 'schedule' ]}, // , 'draft'
					{id: 'schedule', 	label: this.$t('mycampaigns_22')[4], icon: 'ti-calendar',		status: [ 'new', 'schedule' ]}, // Change due time
					{id: 'stop', 		label: this.$t('mycampaigns_22')[5], icon: 'ti-power-off',		status: [ 'run', 'suspend' ]},
					{id: 'pause', 		label: this.$t('mycampaigns_22')[6], icon: 'ti-control-pause',	status: [ 'run' ]},
					{id: 'resume', 		label: this.$t('mycampaigns_22')[7], icon: 'ti-control-play', 	status: [ 'suspend' ]},
					{id: 'sendtest', 	label: this.$t('mycampaigns_22')[8], icon: 'ti-email', 			status: [ 'new', 'schedule', 'run', 'suspend', 'finish' ]},
					{id: 'rcpslist', 	label: this.$t('mycampaigns_22')[9], icon: 'fa fa-users',	 	status: [ 'new', 'schedule', 'run', 'suspend', 'finish' ]},
					{id: 'delete', 		label: this.$t('mycampaigns_22')[10], icon: 'ti-trash', 		status: [ 'new', 'draft', 'schedule', 'finish' ]}, // , 'suspend'
					{id: 'replicate', 	label: this.$t('mycampaigns_22')[11], icon: 'ti-files',			status: [ 'suspend', 'finish', 'run' ]},
					// {id: 'initialize', 	label: 'Initialize', 		icon: 'ti-reload',			status: [ 'finish' ]},
					
					// {id: 'stats', 	label: 'Stats',			icon: 'ti-reload',		 	status: [ 'finish' ]},
					
					// {id: 'restart', 	label: 'Restart',			icon: 'ti-reload',		 	status: [ 'finish' ]},
					// {id: 'sharetemplate',label: 'Share the template', icon: 'ti-sharethis' , status:[ 'new', 'schedule', 'run', 'suspend', 'finish' ]},  // Mn ba3d
					// {id: 'edit', label: 'Share with my teams', icon: 'ti-sharethis'}, // Mn ba3d
					// {id: 'edit', label: 'Stats',	 		icon: 'ti-pie-chart'}, //  (Mn ba3d)
					// {id: 'edit', label: 'Preview',	 		icon: 'fa fa-eye'},
				],
				
				ExistingFile	: false,
				RefreshFiles	: false,
				SuppFile		: { id: '', FileContent: [], type: 'txt', name: '' },
				CheckSuppFile	: { id: false, name: false, file: false },
				
				DomainName		: '',
				DNSRecords		: [],
				
				CloseOptions	: '',
			}
		},
		methods: {
			// Recipients -------------------------------
			ShowHideColumns(item) {
				let that = this
				that.RcptsColumns.forEach( function(col) {
					if( col.column == item ) {
						col.enable = that.SelectedColumns.includes(item)
					}
				} )
			},
			ShowHideActions() {
				// item
				let that = this
				// that.Loading.RcptList = true
				that.current_page		= 0
				that.last_page			= 0
				that.RecipientsList		= []
				that.GetRecipients()
				// ["fresh", "received", "open", "click", "unsub", "softbounce"]
			},
			ShowHideCols(item) {
				let that = this
				that.columns.forEach( function(col) {
					if( col.column == item ) {
						col.enable = that.SelectedFields.includes(item)
					}
				} )
				that.ChangeConfig( { action: 'mcVisCols', content: that.SelectedFields } )
			},
			
			checkBoxRow(item) {
				let that = this
				
				if( !that.SelectedRcpts.includes(item) ) {
					if( that.SelectedRcpts.length == that.RecipientsList.length - 1 ) {
						that.RcptsColumns[0].checked = true
					}
					that.SelectedRcpts.push(item)
				} else {
					let id = that.SelectedRcpts.indexOf(item)
					that.SelectedRcpts.splice( id, 1 )
					that.RcptsColumns[0].checked = null
				}
				
			},
			
			CountRecipients() {
				
				// Init -----------
				let that = this
				Object.keys( that.count_recipients ).forEach( function(status) {
					that.count_recipients[status] = 0
				} )
				that.RecipientsStatus.forEach(function(status) {
					status.option = status.title
					status.tag	  = ''
				})
				if( that.Recipients.ListIds.length > 0 ) {
					that.MyLists.forEach(function(list) {
						if( that.Recipients.ListIds.includes(list.id) ) {
							Object.keys( that.count_recipients ).forEach( function(status) {
								that.count_recipients[status] += list[ 'emails_' + status ]
							} )
						}
					})
					that.RecipientsStatus.forEach(function(status) {
						status.option = status.title
						status.tag	  = '(' + that.count_recipients[status.id] + ')'
					})
				}
				// Init -----------
				
			},
			ChooseRecipients(element) {
				
				let that = this
				that.Recipients.total_emails = 0
				if ( element == 'list' ) {
					that.CountRecipients()
					that.MyLists.forEach(function(list) {
						if( that.Recipients.ListIds.includes(list.id) ) {
							that.Recipients.total_emails += ( list.emails_count - list[ 'emails_hardbounce' ] )
						}
					})
				} else if( element == 'status' ) {
					if( that.Recipients.RcptsList.length > 0 ) {
						that.Recipients.RcptsList.forEach( function(status) {
							that.Recipients.total_emails += that.count_recipients[status]
						} )
					}
				}
				
			},
			
			ChangeRecipients() {
				
				let that = this
				
				that.ValidateObj.lists_id		= that.Recipients.ListIds.length == 0
				that.ValidateObj.recipients		= that.Recipients.RcptsList.length == 0
				that.ValidateObj.total_emails	= that.Recipients.total_emails == 0
				if ( that.ValidateObj.lists_id || that.ValidateObj.recipients || that.ValidateObj.total_emails ) {
					return false
				}
				
				let url		= '/recipients/change'
				let method	= 'POST'
				let data	= { 
					campaign	 : that.DataCampaign.id,
					recipients	 : that.Recipients.RcptsList,
					total_emails : that.Recipients.total_emails,
					lists_id	 : that.Recipients.ListIds,
					shuffle_rcp	 : that.Recipients.shuffle_rcp
				}
				let request = { url: url, method: method, data: data }
				
				that.Loading.Recipients = true
				that.$store.dispatch('requests', request).then((response) => {
					that.Loading.Recipients = false
					let feedback = response.data
					if( ['success', 'info'].includes(feedback.status) ) {
						that.ToastNotify(feedback.status, feedback.title, feedback.message)
						// that.ToastNotify('success', 'Success', 'Changes saved.')
						that.GetConfig('campaigns')
					}
					that.ModalObj.Recipients = false
				}).catch(({response}) => {
					that.Loading.Recipients = false
					that.ErrorHandler(response)
				} )
				
			},
			
			// Recipients -------------------------------
			
			SuppFileChange(SuppFile) {
				let that = this
				that.SuppFile.id 		  = SuppFile.id
				that.SuppFile.FileContent = SuppFile.FileContent
				that.SuppFile.type		  = SuppFile.type
				that.SuppFile.name		  = SuppFile.name
				
				that.CheckSuppFile.id 	= SuppFile.id != '' ?  false : that.CheckSuppFile.id
				that.CheckSuppFile.name = SuppFile.name != '' ?  false : that.CheckSuppFile.name
				that.CheckSuppFile.file = SuppFile.FileContent.length > 0 ?  false : that.CheckSuppFile.file
			},
			SuppFileValidate() {
				let that = this, CheckProc = false
				
				Object.keys(that.CheckSuppFile).forEach(function( el ) {
					that.CheckSuppFile[el] = false
				})
				if( that.ExistingFile ) {
					that.CheckSuppFile.id 	= (that.SuppFile.id == '')
					if( that.SuppFile.id != '' ) {
						CheckProc = true
						that.SuppFile.name = ''
						that.SuppFile.FileContent = []
					}
				} else {
					that.CheckSuppFile.name = (that.SuppFile.name == '')
					that.CheckSuppFile.file = (that.SuppFile.FileContent.length == 0)
					if(that.SuppFile.FileContent.length > 0 && that.SuppFile.name != '') {
						CheckProc = true
					}
				}
				
				if(CheckProc) {
					
					let url  	= '/suppfile/update'
					let method  = 'POST'
					let data 	= { campaign:that.DataCampaign.id, suppfile: that.SuppFile }
					let request = { url: url, method: method, data: data }
					
					that.Loading.SuppFile = true
					that.$store.dispatch('requests', request).then((response) => {
						that.Loading.SuppFile = false
						let feedback = response.data
						if( feedback.status == 'success' ) {
							that.ToastNotify('success', 'Success', 'Changes saved.')
							that.GetConfig('campaigns')
							that.RefreshFiles = feedback.refresh
						} else {
							location.reload()
						}
					}).catch(({response}) => {
						that.Loading.SuppFile = false
						that.ErrorHandler(response)
					} )
					
				}
			},
			
			ChangeDetails( field, NewValue, action ) {
				let that = this
				if( action == 'ChangeDetails' ) {
					that.Campaigns[that.CurrentRowId][field] = NewValue
				} else if( action == 'Refresh' ) {
					that.GetConfig('campaigns')
					if( field == 'sender' ) {
						that.ToastNotify('success', 'Success', 'Changes saved')
						that.ModalObj.EditCampaign = false
					}
				}
			},
			SaveCampaign(action) {
				let that = this
				
				let url  	= '/campaign/schedule'
				let method  = 'POST'
				let data 	= { action:action, campaign: that.DataCampaign.id, datetime: that.DataCampaign.schedule }
				let request = { url: url, method: method, data: data }
				
				that.Loading[action] = true
				that.$store.dispatch('requests', request).then((response) => {
					let feedback  	= response.data
					let status 		= feedback.status
					
					if ( status == 'success' ) {
						that.ToastNotify('success', 'Success', 'Campaign started successfully')
						that.GetConfig('campaigns')
					}
					
					that.ModalObj[action] = false
					that.Loading[action]  = false
				}).catch(({response}) => {
					that.Loading[action] = false
					that.ErrorHandler(response)
				} )
				
			},
			ChangeTabs(tab) {
				let that = this
				that.CurrentTab = tab.id
			},
			
			// pagination ------------------
			onPageChange(page) {
				this.currentPage = page;
				this.GetCampaigns()
			},
			// pagination ------------------
			
			CampaignAction(action, id, entry) {
				let that = this
				that.CurrentRowId = id
				that.DataCampaign = { name: '', schedule: '' }
				that.CurrentTab = 'general'
				Object.keys(entry).forEach(function(field) {
					that.DataCampaign[field] = that.isIsset(entry[field]) ? entry[field] : ''
				})
				// 
				that.ExistingFile = false
				that.SuppFile.id  = entry.suppfile != null ? entry.suppfile : ''
				
				that.TabsItems.forEach( function(tab) {
					if (tab.id == 'dnsrecords') {
						let CheckAccess = (entry.sender != null && entry.sender.type == 'paid' && entry.domain != null)
						tab.enable = (CheckAccess) ? false : null
						// tab.enable = (entry.domain == null) ? entry.domain : false
					} else if ( ['content', 'sender'].includes(tab.id) ) {
						tab.enable = (entry.status == 'run') ? null : false
					}
				} )
				if( entry.domain == null ) {
					that.DomainName	= ''
					that.DNSRecords	= []
				} else {
					that.DomainName	= entry.domain.main_domain
					that.DNSRecords	= entry.domain.dns_records
				}
				that.CloseOptions = 'close'
				if( action.id == 'delete' ) {
					that.ModalObj.DeleteCampaign = true
				} else if( action.id == 'edit' ) {
					that.ModalObj.EditCampaign = true
				} else if( action.id == 'schedule' ) {
					that.DataCampaign.schedule = that.DataCampaign.started_at
					that.ModalObj.Schedule	   = true
				} else if( action.id == 'sendtest' ) {
					that.$store.state.loading = true
					
					let url  	= '/campaign/sendtest'
					let method  = 'POST'
					let data 	= { campaign:entry.id }
					let request = { url: url, method: method, data: data }
					
					that.$store.dispatch('requests', request).then((response) => {
						let status = response.data.status
						let message= response.data.message
						if ( status == 'success' ) {
							// that.ToastNotify('success', 'Success', 'Email sended successfully please check your inbox')
							that.ToastNotify('success', 'Success', 'Success..')
						} else {
							// let title = status == 'quota' ? 'Sender volume' : 'Opps !'
							that.ToastNotify('warning', 'Opps !', message)
						}
						that.$store.state.loading = false
					}).catch(({response}) => {
						that.$store.state.loading = false
						that.ErrorHandler(response)
					} )
					
				} else if( action.id == 'run' ) {
					that.$store.state.loading = true
					
					let url  	= '/campaign/run'
					let method  = 'POST'
					let data 	= { campaign:entry.id }
					let request = { url: url, method: method, data: data }
					
					that.$store.dispatch('requests', request).then((response) => {
						let status = response.data.status
						let message= response.data.message
						if ( status == 'success' ) {
							that.ToastNotify('success', 'Success', 'Campaign started successfully')
							that.GetConfig('campaigns')
						} else {
							that.$store.state.loading = false
							let title = status == 'quota' ? 'Sender volume' : 'Opps !'
							that.ToastNotify('warning', title, message)
						}
					}).catch(({response}) => {
						that.$store.state.loading = false
						that.ErrorHandler(response)
					} )
					
				} else if( action.id == 'pause' ) {
					that.$store.state.loading = true
					
					let url  	= '/campaign/pause'
					let method  = 'POST'
					let data 	= { campaign:entry.id }
					let request = { url: url, method: method, data: data }
					
					that.$store.dispatch('requests', request).then((response) => {
						let status = response.data.status
						let message= response.data.message
						if ( status == 'success' ) {
							that.ToastNotify('info', 'Notice', 'Campaign paused successfully')
							that.GetConfig('campaigns')
						} else {
							that.$store.state.loading = false
							that.ToastNotify('warning', 'Opps !', message)
						}
					}).catch(({response}) => {
						that.$store.state.loading = false
						that.ErrorHandler(response)
					} )
					
				} else if( action.id == 'stop' ) {
					that.$store.state.loading = true
					
					let url  	= '/campaign/stop'
					let method  = 'POST'
					let data 	= { campaign: entry.id }
					let request = { url: url, method: method, data: data }
					
					that.$store.dispatch('requests', request).then((response) => {
						let status = response.data.status
						let message= response.data.message
						if ( status == 'success' ) {
							that.ToastNotify('info', 'Notice', 'Campaign stoped successfully')
							that.GetConfig('campaigns')
						} else {
							that.$store.state.loading = false
							that.ToastNotify('warning', 'Opps !', message)
						}
					}).catch(({response}) => {
						that.$store.state.loading = false
						that.ErrorHandler(response)
					} )
					
				} else if( action.id == 'resume' ) {
					that.$store.state.loading = true
					
					let url  	= '/campaign/resume'
					let method  = 'POST'
					let data 	= { campaign: entry.id }
					let request = { url: url, method: method, data: data }
					
					that.$store.dispatch('requests', request).then((response) => {
						let status = response.data.status
						let message= response.data.message
						if ( status == 'success' ) {
							that.ToastNotify('success', 'Success', 'Send resumed successfully')
							that.GetConfig('campaigns')
						} else {
							that.$store.state.loading = false
							that.ToastNotify('warning', 'Opps !', message)
						}
					}).catch(({response}) => {
						that.$store.state.loading = false
						that.ErrorHandler(response)
					} )
				} else if( action.id == 'replicate' ) {
					that.ModalObj.Replicate = true
				} else if( action.id == 'initialize' ) {
					that.ModalObj.Initialize = true
				} else if( action.id == 'recipients' ) {
					
					that.ValidateObj.lists_id		= false
					that.ValidateObj.recipients		= false
					that.ValidateObj.total_emails	= false
					
					that.ModalObj.Recipients = true
					
					that.Recipients.total_emails = entry.total_emails
					let MyLists = []
					that.MyLists.forEach( function(item) {
						MyLists.push(item.id)
					} )
					if( that.isIsset(entry.config.lists_id) ) {
						that.Recipients.ListIds.splice(0, that.Recipients.ListIds.length)
						entry.config.lists_id.forEach( function(item) {
							if( MyLists.includes(item) ) {
								that.Recipients.ListIds.push(item)
							}
						} )
					}
					if( that.isIsset(entry.config.recipients) ) {
						that.Recipients.RcptsList.splice(0, that.Recipients.RcptsList.length)
						entry.config.recipients.forEach( function(item) {
							that.Recipients.RcptsList.push(item)
						} )
					}
					if( that.isIsset(entry.config.shuffle_rcp) ) {
						that.Recipients.shuffle_rcp = entry.config.shuffle_rcp
					}
					that.CountRecipients()
					
				} else if( action.id == 'rcpslist' ) {
					
					if( entry.sender == null ) {
						that.ToastNotify('warning', 'Opps !', 'Sender not exist !')
						return false
					}
					
					that.SelectedActions.splice(0, -1)
					let actions = ["fresh", "sent", "received", "open", "click", "unsub", "softbounce"]
					actions.forEach( function(col) {
						that.SelectedActions.push( col )
					} )
					
					that.last_page		= 0
					that.current_page	= 0
					that.RecipientsList	= []
					that.GetRecipients()
					that.ModalObj.RcpsList 	= true
					
					if( that.VisibleFields.length == 0 ) {
						that.RcptsColumns.forEach( function(col) {
							if( !['checkBox', 'id', 'options'].includes(col.column) ) {
								that.VisibleFields.push( { id: col.column, option: col.title } )
							}
						} )
					}
					if( that.SelectedColumns.length == 0 ) {
						that.RcptsColumns.forEach( function(col) {
							if( !['checkBox', 'id', 'options'].includes(col.column) && col.enable ) {
								that.SelectedColumns.push( col.column )
							}
						} )
					}
					
					/* {
						"city": "Casablanca",
						"region": "Casablanca-Settat",
						"timezone": "Africa/Casablanca",
						"countryname": "Morocco",
						"continentname": "Africa",
						"currencycode": "MAD",
						
						"regionname": "Casablanca",
						"ineu": 0,
						"regioncode": "CAS",
						"countrycode": "MA",
						"continentcode": "AF",
						"currencysymbol": "DH",
						"currencyconverter": "9.2245",
						"currencysymbolutf8": "DH",
						"locationaccuracyradius": "1"
						"dmacode": "",
						"areacode": "",
						"latitude": "33.5922",
						"euvatrate": false,
						"longitude": "-7.6184",
					} */
					
					/*
					// id           
					// lead_id      
					// campaign     
					full_name    
					first_name   
					last_name    
					email        
					phone        
					// list_id      
					action       
					ip_address   
					tracking     
					custom_fields
					sort_date    
					device       
					referer      
					user_agent   
					string_query 
					datetime_zone
					created_at   
					updated_at    */
					
				}
			},
			scroll() {
				// Detect when scrolled to bottom.
				const listElm = document.querySelector('.content-recipientslist');
				let that = this
				listElm.addEventListener('scroll', e => {
					if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
						if( e.type == 'scroll' ) {
							that.GetRecipients()
						}
					}
				})
			},
			GetRecipients() {
				
				let that 	= this, actions = []
				
				if( that.last_page >= that.current_page && !that.Loading.RcptList ) {
					
					that.SelectedActions.forEach( function(action) {
						actions.push( action == 'softbounce' ? 'bounced' : action )
					} )
					
					that.current_page++
					let url  	= '/campaign/recipients?page=' + that.current_page
					let method  = 'POST'
					let data 	= { campaign: that.DataCampaign.id, actions: actions }
					let request = { url: url, method: method, data: data }
					that.Loading.RcptList = true
					that.$store.dispatch('requests', request).then((response) => {
						that.Loading.RcptList = false
						if( response.data.status == 'success' ) {
							let feedback	= response.data.recipients
							let recipients	= feedback.data
							that.last_page	= feedback.last_page
							recipients.forEach( function(recipient) {
								let tracking = recipient.tracking != null ? JSON.parse( recipient.tracking ) : recipient.tracking
								
								that.RecipientsList.push( {
									full_name		: recipient.full_name,
									first_name		: recipient.first_name,
									last_name		: recipient.last_name,
									email			: recipient.email,
									phone			: recipient.phone,
									action			: recipient.action,
									device			: recipient.device == null ? '' : recipient.device,
									datetime_zone	: recipient.datetime_zone,
									
									city			: tracking == null ? '' : tracking.city,
									region			: tracking == null ? '' : tracking.region,
									timezone		: tracking == null ? '' : tracking.timezone,
									countryname		: tracking == null ? '' : tracking.countryname,
									continentname	: tracking == null ? '' : tracking.continentname,
									currencycode	: tracking == null ? '' : tracking.currencycode,
								} )
							} )
							
						} else {
							that.ToastNotify('warning', 'Opps !', response.data.message)
						}
					}).catch(({response}) => {
						that.Loading.RcptList = false
						that.ErrorHandler(response)
					} )
				}
			},
			
			InitializeCampaign() {
				let that = this
				that.Loading.Initialize = true
				let url  	= '/campaign/initialize'
				let method  = 'POST'
				let data 	= { campaign: that.DataCampaign.id }
				let request = { url: url, method: method, data: data }
				that.$store.dispatch('requests', request).then((response) => {
					let status = response.data.status
					let message= response.data.message
					if ( status == 'success' ) {
						that.ToastNotify('success', 'Success', 'Campaign initialized successfully')
						that.GetConfig('campaigns')
						that.$store.dispatch('SidebarCounter')
						that.ModalObj.Initialize = false
					} else {
						that.ToastNotify('warning', 'Opps !', message)
					}
					that.Loading.Initialize = false
				}).catch(({response}) => {
					that.ErrorHandler(response)
					that.Loading.Initialize  = false
					that.ModalObj.Initialize = false
				} )
			},
			ReplicateCampaign() {
				let that = this
				that.Loading.Replicate = true
				let url  	= '/campaign/replicate'
				let method  = 'POST'
				let data 	= { campaign: that.DataCampaign.id }
				let request = { url: url, method: method, data: data }
				that.$store.dispatch('requests', request).then((response) => {
					let status = response.data.status
					let message= response.data.message
					if ( status == 'success' ) {
						that.ToastNotify('success', 'Success', 'Campaign replicated successfully')
						that.GetConfig('campaigns')
						that.$store.dispatch('SidebarCounter')
						that.ModalObj.Replicate = false
					} else {
						that.ToastNotify('warning', 'Opps !', message)
					}
					that.Loading.Replicate = false
				}).catch(({response}) => {
					that.ErrorHandler(response)
					that.Loading.Replicate  = false
					that.ModalObj.Replicate = false
				} )
			},
			DeleteCampaign() {
				let that = this
				that.Loading.DeleteCampaign = true
				
				let url  	= '/campaign/delete'
				let method  = 'delete'
				let data 	= { id:that.DataCampaign.id }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then(() => {
					that.GetConfig('campaigns')
					that.Loading.DeleteCampaign  = false
					that.ModalObj.DeleteCampaign = false
					that.$store.dispatch('SidebarCounter')
				}).catch(({response}) => {
					that.GetConfig('campaigns')
					that.ErrorHandler(response)
					that.Loading.DeleteCampaign  = false
					that.ModalObj.DeleteCampaign = false
				})
			},
			
			ToggleStatus(tab) {
				let that = this
				that.TabsStatus.forEach(function(item) {
					if( item.id == tab.id ) {
						if( !item.enable ) {
							that.status = item.id
							that.GetCampaigns()
						}
						item.enable = true
					} else {
						item.enable = false
					}
				})
			},
			
			GetConfig(action = '') {
				
				let that = this
				that.$store.state.loading = true
				
				// CountStatus	 : { all: 0, 'new': 0, draft: 0, schedule: 0, run: 0, suspend: 0, finish: 0 },
				let url  	= '/email/campaign/config'
				let method  = 'POST'
				let data 	= { search:that.search, daterange:that.daterange }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => {
					Object.keys(that.CountStatus).forEach( function(status) {
						that.CountStatus[status] = 0
					})
					
					let counter = response.data.counter
					Object.keys(counter).forEach( function(status) {
						that.CountStatus[status] = counter[status]
						that.CountStatus.all 	+= counter[status]
					})
					
					// ----------------------------- Recipients
					that.MyLists = []
					let lists  	 = response.data.lists
					let label 	 = ''
					lists.forEach(function(list) {
						let element = list
						label		= list.emails_count > 1 ? ' Emails' : ' Email'
						element['option'] = list.name
						element['tag']	  = '(' + list.emails_count + label + ')'
						that.MyLists.push( element )
					})
					// ----------------------------- Recipients
					
					that.$store.state.loading = false
					
					if( action == 'campaigns' ) {
						that.GetCampaigns()
					}
					
				}).catch(({response}) => {
					that.$store.state.loading = false
					that.ErrorHandler(response)
				} )
				
			},
			
			GetCampaigns(action = '') {
				
				let that 		= this, status_campaigns = []
				let currentPage = that.currentPage
				that.$store.state.loading = true
				
				Object.keys(that.CountStatus).forEach( function(status) {
					if( status != 'all' ) {
						if( that.status == 'all' || that.status == status ) {
							status_campaigns.push(status)
						}
					}
				})
				
				// CountStatus	 : { all: 0, 'new': 0, draft: 0, schedule: 0, run: 0, suspend: 0, finish: 0 },
				let url  	= '/email/campaigns/get?page=' + currentPage
				let method  = 'POST'
				let data 	= { status: status_campaigns, search:that.search, daterange:that.daterange }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => {
					that.Campaigns	 = []
					let feedback	 = response.data
					let campaigns 	 = feedback.data
					that.currentPage = feedback.current_page
					that.lastPage	 = feedback.last_page
					that.totalCampaigns = feedback.total
					if( that.CountStatus[that.status] != feedback.total || action == 'filter' ) {
						that.GetConfig()
					}
					campaigns.forEach(function(campaign) {
						let configs = that.isIsset(campaign.config) ? campaign.config : {}
						let sender_name = configs != {} ? ( that.isIsset(campaign.config.sender_name) ? campaign.config.sender_name : '') : ''
						let domain		= ( that.isIsset(campaign.sender) && that.isIsset( campaign.sender.domain ) ) ? campaign.sender.domain : null
						let sender		= that.isIsset(campaign.sender) ? campaign.sender : null
						// let TotalRest	= 0
						that.Campaigns.push({
							id 			: campaign.id,
							name		: campaign.name,
							status		: campaign.status,
							received	: campaign.total_received,
							openers		: campaign.total_open,
							clickers	: campaign.total_click,
							unsub		: campaign.total_unsub,
							bounced		: campaign.total_bounced,
							createdAt	: campaign.created_at,
							email_test	: campaign.email_test,
							vertical	: campaign.vertical,
							new_vertical: campaign.new_vertical,
							total_emails: campaign.total_emails,
							total_sent	: campaign.total_sent,
							// total_rest	: TotalRest,
							started_at	: campaign.started_at,
							finished_at	: campaign.finished_at,
							content		: campaign.content,
							suppfile	: campaign.supp_file,
							sender		: sender,
							sender_title: sender != null ? sender.title : '',
							domain		: domain,
							
							config		: configs,
							domain_name	: domain != null ? domain.main_domain : '',
							sender_name	: sender_name,
						})
					})
					
					that.$store.state.loading = false
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.$store.state.loading = false
				} )
				
			}
		},
		mounted() {
			let that = this
			that.$store.state.selectedPage = that.$route.name
			// that.$store.state.loading = false
			that.scroll()
			that.GetConfig('campaigns')
			
			var tomorrow = new Date();
			tomorrow.setDate(new Date().getDate()+1);
			tomorrow = tomorrow.getFullYear() + '-' + ((tomorrow.getMonth() < 9) ? '0' : '') + (tomorrow.getMonth() + 1) + '-' + ((tomorrow.getDate() < 10) ? '0' : '') + tomorrow.getDate()
			that.ScheduleMinDate = tomorrow
			
			// ['total_emails', 'total_sent', 'received', 'bounced', 'unsub']
			// , 'total_emails'
			that.columns.forEach( function(col) {
				if( !['id', 'name', 'actions'].includes(col.column) ) {
					that.VisibleColumns.push( { id: col.column, option: col.title } )
				}
			} )
			let mcVisCols = that.isIsset(that.$store.state.user.config.mcVisCols) ? that.$store.state.user.config.mcVisCols : []
			that.columns.forEach( function(col) {
				if( !['id', 'name', 'actions'].includes(col.column) ) {
					col.enable = ( mcVisCols.length ) ? mcVisCols.includes(col.column) : col.enable
					if(col.enable) {
						that.SelectedFields.push( col.column )
					}
				}
			} )
		},
		watch: {
			daterange: function() {
				this.GetCampaigns('filter')
			},
			search: function() {
				let that = this
				setTimeout(function() {
					that.GetCampaigns('filter')
				}, 1000)
			},
			Recipients: {
				handler: function (val) {
					let that = this, total_emails = 0
					if( val.RcptsList.length > 0 ) {
						val.RcptsList.forEach( function(status) {
							total_emails += that.count_recipients[status]
						} )
					}
					val.total_emails = val.total_emails >= total_emails ? total_emails : val.total_emails
				},
				deep: true
			},
			
		},
		filters: {
			MaxLength: function (string) {
				if( string != null && string != '' ) {
					return string.length > 26 ? string.substring(0, 26) + "..." : string
				}
				return ''
			}
		}
	}
</script>
<style lang="scss">
	.my-campaigns {
		.status-tabs {
			margin-bottom: 10px;
			border-bottom: 1px solid #eee;
			.nav-item {
				&.active, &:hover {
					border-color: #fff;
					border-raduis: 0px;
					border-bottom: 2px solid #0c7cd5;
				}
			}
		}
	}
	.content-scroll {
		overflow-y: scroll;
		max-height: calc(80vh - 100px);
	}
	
</style>