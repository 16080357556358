<template>
	
	<div class="container-fluid my-campaigns">
		
		<div class="row mT-10 mB-30">
			<div class="col-6">
				<h4 class="c-grey-900 m-0">{{ $t('FbCampaigns_1')[0] }}</h4>
			</div>
			<div class="col-6 text-right">
				<router-link :to="{name: 'campaign.create'}" class="btn btn-primary btn-sm">
					<i class="fa fa-plus"></i> {{ $t('FbCampaigns_1')[1] }}
				</router-link>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="bgc-white bd bdrs-3 p-20 mB-20">
					<div class="row form-group">
						<div class="col-4">
							<multi-select :options="VisibleColumns" :selected="SelectedFields" label="Columns" placeholderType="title" :placeholder="$t('FbCampaigns_2')[0]" 
							:caret="true" width="175px" @change="ShowHideCols"></multi-select>
						</div>
						<div class="col-8">
							<div class="form-inline pull-right">
								<div class="form-group">
									<date-picker right v-model="daterange"></date-picker>
								</div>
								<div class="form-group ml-2">
									<input type="search" class="form-control" :placeholder="$t('FbCampaigns_2')[1]" v-model="search" />
								</div>
							</div>
						</div>
					</div>
					
					<div class="row">
						<div class="col-md-12">
							<simple-table :dataItems="Campaigns" :columns="columns" :footer="true"><!-- :responsive="true" -->
								<template v-slot:body="slotProps">
									<tr v-for="(entry, id) in slotProps.body" v-bind:key="id">
										<template v-for="(item, key) in columns">
											<td v-if="item.enable" v-bind:key="key">
												<div v-if="item.column == 'actions'" class="text-center">
													<drop-down :items="columns" :action="CloseOptions" @toggle="CloseOptions = ''">
														<template v-slot:label>
															<i class="fa fa-ellipsis-v c-pointer p-3 m-auto" style="font-size:18px;color: #2d2d2d;"></i>
														</template>
														<template v-slot:options>
															<li v-for="(action, index) in OptionsAction" v-bind:key="`li-${index}`" @click="CampaignAction(action, id, entry)" class="icon-middle">
																<a href="javascript:void(0);" class="d-b td-n p-5 bgcH-grey-100 c-grey-700" v-if="action.status.includes(entry['status'])">
																	<i :class="[action.icon, 'm-5']"></i>
																	<span>{{ action.label }}</span>
																</a>
															</li>
														</template>
													</drop-down>
												</div>
												<div v-else-if="item.column == 'name'">
													<div style="color: rgb(121, 121, 121);">
														<div :title="entry[item.column]">
															{{ entry[item.column] | MaxLength }} 
															<span :class="['badge', 'badge-' + BadgeStatus[entry['status']], 'text-capitalize' ]">
																{{ entry['status'] }}
															</span>
														</div>
														<b class="fs-11">{{ $t('FbCampaigns_3') }} : </b><span>{{ entry['created_at'] | date_format }}</span>
													</div>
												</div>
												<div v-else-if="item.column == 'objective'">
													<div class="text-capitalize">
														{{ entry[item.column].replace('_', ' ').toLowerCase() }}
													</div>
												</div>
												<div v-else :class="[item.class]">
													{{ entry[item.column] }}
												</div>
											</td>
										</template>
									</tr>
								</template>
							</simple-table>
							<pagination v-bind:lastPage="lastPage" v-on:page-changed="onPageChange" v-bind:current-page="currentPage" :maxVisiblePages="maxVisiblePages" />
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	import { SimpleTable, DropDown, Pagination, DatePicker, MultiSelect } from "@/components";
	
	export default {
		name: 'MyCampaigns',
		components: {
			//
			SimpleTable,
			DropDown,
			Pagination,
			DatePicker,
			
			MultiSelect,
			
		},
		data() {
			return {
				// Recipients -------------------------------
				RecipientsStatus: [
					{ id: 'fresh'	  ,	option: this.$t('FbCampaigns_4')[0], title: this.$t('FbCampaigns_4')[0], tag: '' },
					{ id: 'received'  , option: this.$t('FbCampaigns_4')[1], title: this.$t('FbCampaigns_4')[1], tag: '' },
					{ id: 'open'	  , option: this.$t('FbCampaigns_4')[2], title: this.$t('FbCampaigns_4')[2], tag: '' },
					{ id: 'click'	  ,	option: this.$t('FbCampaigns_4')[3], title: this.$t('FbCampaigns_4')[3], tag: '' },
					{ id: 'unsub'	  , option: this.$t('FbCampaigns_4')[4], title: this.$t('FbCampaigns_4')[4], tag: '' },
					{ id: 'softbounce',	option: this.$t('FbCampaigns_4')[5], title: this.$t('FbCampaigns_4')[5], tag: '' }, // Soft Bounce
				],
				MyLists			: [],
				
				count_recipients: { fresh : 0, received : 0, open : 0, click : 0, unsub : 0, softbounce : 0, hardbounce: 0 },
				ValidateObj		: { lists_id: false, recipients: false, total_emails: false },
				
				RecipientsList	: [],
				SelectedRcpts	: [],
				
				SelectedColumns	: [],
				VisibleFields	: [],
				current_page : 0,
				// Recipients -------------------------------
				
				
				// Tabs details ----------------------------
				
				TabsItems 	 : [
					{ id: 'general', 	label: this.$t('FbCampaigns_5')[0], enable:	false, count: ''  },
					{ id: 'content', 	label: this.$t('FbCampaigns_5')[1], enable:	false, count: ''  },
					{ id: 'sender', 	label: this.$t('FbCampaigns_5')[2], enable:	false, count: '' },
					{ id: 'dnsrecords', label: this.$t('FbCampaigns_5')[3], enable:	null,  count: '' },
					
					// { id: 'suppfile', 	label: "Suppression File", 	enable:	false, count: '' },
					// { id: 'recipients', label: "Recipients", 	  	enable:	false,	count: '0' },
					// { id: 4, label: "Notes",		  	enable:	false, 	count: '' },
				],
				CurrentTab	 : 'general',
				
				// Tabs details ----------------------------
				
				// Pagination ----------------------------
				currentPage		: 1,
				lastPage		: 1,
				maxVisiblePages	: 3,
				totalCampaigns	: 0,
				// itemsPerPage	: 10,
				// Pagination ----------------------------
				
				// Filter ----------------------------
				status			: 'all',
				search			: '',
				daterange		: '',
				// Filter ----------------------------
				
				ScheduleMinDate	: '',
				ModalObj		: {DeleteCampaign: false, EditCampaign: false, Schedule: false, Recipients: false, RcpsList: false, Replicate: false, Initialize: false},
				Loading			: {DeleteCampaign: false, Schedule: false, SuppFile: false, Recipients: false, Replicate: false, Initialize: false},
				DataCampaign 	: { name: '', schedule: '' },
				
				Recipients		: { ListIds: [], RcptsList: [], total_emails: false, shuffle_rcp: false },
				
				CurrentRowId	: '',
				
				
				BadgeStatus	 : { pending: 'primary' }, // , draft: 'dark', schedule: 'warning', run: 'success', suspend: 'secondary', finish: 'info'
				
				TabsStatus	 : [
					{ id: 'all', 		 title: this.$t('FbCampaigns_6')[0], enable:true },
					{ id: 'new',		 title: this.$t('FbCampaigns_6')[1], enable:false },
					{ id: 'draft',		 title: this.$t('FbCampaigns_6')[2], enable:false },
					{ id: 'schedule',	 title: this.$t('FbCampaigns_6')[3], enable:false },
					{ id: 'run',	 	 title: this.$t('FbCampaigns_6')[4], enable:false }, // inprogress
					{ id: 'suspend',	 title: this.$t('FbCampaigns_6')[5], enable:false },
					{ id: 'finish',		 title: this.$t('FbCampaigns_6')[6], enable:false },
				],
				columns		 : [
					{ title: this.$t('FbCampaigns_7')[0], column: 'id' 		    , enable: false, class: ''				},
					{ title: this.$t('FbCampaigns_7')[1], column: 'name' 	    , enable: true , class: ''				},
					{ title: this.$t('FbCampaigns_7')[2], column: 'sender_title', enable: false, class: ''				},
					{ title: this.$t('FbCampaigns_7')[3], column: 'objective'	, enable: true , class: '' 				},
					{ title: this.$t('FbCampaigns_7')[4], column: 'actions'   	, enable: true , class: 'text-center'	}
				],
				SelectedFields	: [],
				VisibleColumns	: [],
				Campaigns	 	: [],
				
				OptionsAction	: [
					{id: 'run', 		label: this.$t('FbCampaigns_8')[0],	icon: 'ti-target',			status: [ 'new', 'schedule' ]},
					{id: 'edit',	 	label: this.$t('FbCampaigns_8')[1],	icon: 'ti-pencil-alt', 		status: [ 'draft' ]},
				],
				
				ExistingFile	: false,
				RefreshFiles	: false,
				SuppFile		: { id: '', FileContent: [], type: 'txt', name: '' },
				CheckSuppFile	: { id: false, name: false, file: false },
				
				DomainName		: '',
				DNSRecords		: [],
				
				CloseOptions	: '',
			}
		},
		methods: {
			// Recipients -------------------------------
			onSignIn(googleUser) {
				var profile = googleUser.getBasicProfile();
				console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
				console.log('Name: ' + profile.getName());
				console.log('Image URL: ' + profile.getImageUrl());
				console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
			},
			ShowHideCols(item) {
				let that = this
				that.columns.forEach( function(col) {
					if( col.column == item ) {
						col.enable = that.SelectedFields.includes(item)
					}
				} )
				that.ChangeConfig( { action: 'mcVisCols', content: that.SelectedFields } )
			},
			// Recipients -------------------------------
			
			// pagination ------------------
			onPageChange(page) {
				this.currentPage = page;
				this.GetCampaigns()
			},
			// pagination ------------------
			
			CampaignAction(action, id, entry) {
				let that = this
				that.CurrentRowId = id
				that.DataCampaign = { name: '', schedule: '' }
				that.CurrentTab = 'general'
				Object.keys(entry).forEach(function(field) {
					that.DataCampaign[field] = that.isIsset(entry[field]) ? entry[field] : ''
				})
			},
			
			GetCampaigns() {
				
				let that 		= this, status_campaigns = []
				let currentPage = that.currentPage
				that.$store.state.loading = true
				
				let url  	= '/fb/campaigns/get?page=' + currentPage
				let method  = 'POST'
				let data 	= { status: status_campaigns, search:that.search, daterange:that.daterange }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => {
					that.Campaigns	 = []
					let feedback	 = response.data
					let campaigns 	 = feedback.data
					that.currentPage = feedback.current_page
					that.lastPage	 = feedback.last_page
					that.totalCampaigns = feedback.total
					
					campaigns.forEach(function(campaign) {
						that.Campaigns.push({
							id 			: campaign.id,
							name		: campaign.name,
							status		: campaign.status,
							objective	: campaign.objective,
							created_at	: campaign.created_at,
						})
					})
					
					that.$store.state.loading = false
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.$store.state.loading = false
				} )
				
			}
			
		},
		mounted() {
			let that = this
			that.$store.state.selectedPage = that.$route.name
			// that.$store.state.loading = false
			
			that.GetCampaigns()
		},
		watch: {
			daterange: function() {
				this.GetCampaigns('filter')
			},
			search: function() {
				let that = this
				setTimeout(function() {
					that.GetCampaigns('filter')
				}, 1000)
			},
		},
		filters: {
			MaxLength: function (string) {
				if( string != null && string != '' ) {
					return string.length > 26 ? string.substring(0, 26) + "..." : string
				}
				return ''
			}
		}
	}
</script>
<style lang="scss">
</style>